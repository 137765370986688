import { Col, Row } from "antd"
import CustomModal from "src/components/Modal/CustomModal"
import SvgIcon from "src/components/SvgIcon"
import { StyleSelectRegisterModal } from "../../styled"

const SelectRegisterModal = ({
  openSelectRegisterModal,
  handleCancel,
  handleLogin,
  handleRegisterUser,
  handleRegisterEnterprise,
  setAccountType,
}) => {
  return (
    <CustomModal
      title="Chọn dạng tài khoản"
      footer={null}
      open={openSelectRegisterModal}
      onCancel={handleCancel}
    >
      <StyleSelectRegisterModal>
        <Row gutter={25} className="div-modal-select">
          <Col>
            <div
              onClick={() => {
                handleRegisterUser()
                handleCancel()
              }}
              className="modal-select"
            >
              <SvgIcon name="person-register" />
              <div className="title">Cá nhân</div>
            </div>
          </Col>
          <Col>
            <div
              onClick={() => {
                handleRegisterEnterprise()
                handleCancel()
                setAccountType(3)
              }}
              className="modal-select"
            >
              <SvgIcon name="enterprise-register" />
              <div className="title">Doanh nghiệp</div>
            </div>
          </Col>
          <Col>
            <div
              onClick={() => {
                handleRegisterEnterprise()
                handleCancel()
                setAccountType(4)
              }}
              className="modal-select"
            >
              <SvgIcon name="test-site-register" />
              <div className="title">Điểm xét nghiệm</div>
            </div>
          </Col>
        </Row>

        <Row>
          <span className="md-bottom-text">
            <span>
              Bạn đã có tài khoản?{" "}
              <span
                className="link-login"
                onClick={() => {
                  handleCancel()
                  handleLogin()
                }}
              >
                Đăng nhập
              </span>{" "}
              ngay
            </span>
          </span>
        </Row>
      </StyleSelectRegisterModal>
    </CustomModal>
  )
}

export default SelectRegisterModal
