import { Col, Form, Input, Row, TreeSelect, Upload } from "antd"
import { useEffect } from "react"
import { useState } from "react"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import SelectAddress from "src/components/SelectAddress"
import SpinCustom from "src/components/Spin"
import SvgIcon from "src/components/SvgIcon"
import { getRegexEmail, getRegexMobile } from "src/lib/stringsUtils"
import { normFile } from "src/lib/utils"
import AuthService from "src/services/AuthService"
import FileService from "src/services/FileService"
import RegionService from "src/services/RegionService"

const RegisterEnterpriseModal = ({ open, handleCancel, AccountType }) => {
  const [listAddress, setListAddress] = useState([])

  useEffect(() => {
    if (AccountType === 4 && open) getListAddress()
  }, [AccountType])

  const format = items =>
    items.map(item => ({
      ...item,
      key: item.RegionID,
      value: item.RegionID,
      title: item.RegionName,
      isLeaf: item.RegionLevel === 4,
    }))

  const getListAddress = async () => {
    try {
      setLoading(true)
      const res = await RegionService.getByRegionId({ regionId: 234 })
      if (res.isOk) {
        const data = format(res.Object)
        setListAddress(data)
      }
    } finally {
      setLoading(false)
    }
  }

  const onLoadData = async node => {
    const res = await RegionService.getByRegionId({ regionId: node.key })
    const child = format(res.Object.filter(item => item.ParentID === node.key))
    setListAddress(updateTreeNode(listAddress, node.key, child))
  }

  const updateTreeNode = (treeData, key, children) =>
    treeData.map(node => {
      if (node.key === key) {
        return { ...node, children }
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeNode(node.children, key, children),
        }
      }
      return node
    })
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const handlRegister = async () => {
    try {
      setLoading(true)
      const values1 = await form.validateFields()
      // const values = {
      //   ...values1,
      //   // CreaterUser: undefined,
      //   ProvinceID: values1?.provinceId,
      //   DistrictID: values1?.districtId,
      //   WardID: values1?.wardId,
      //   provinceId: undefined,
      //   districtId: undefined,
      //   wardId: undefined,
      // }
      const values = {
        AccountName: values1?.AccountName,
        Representative: values1?.Representative,
        Position: values1?.Position,
        TaxCode: values1?.TaxCode,
        PhoneNumber: values1?.PhoneNumber,
        FaxNumber: values1?.FaxNumber,
        Email: values1?.Email,
        Address: values1?.Address,
        AreaInCharge: values1?.AreaInCharge,
        LicenseFileList: values1?.LicenseFileList,

        ProvinceID: values1?.provinceId,
        DistrictID: values1?.districtId,
        WardID: values1?.wardId,
      }
      // const res = await AuthService.businessRegister({
      //   AccountType: AccountType,
      //   ...values,
      //   AreaInCharge: values?.AreaInCharge?.join(),
      // })
      // if (res?.isError) return

      // let resUpload
      // if (values?.file?.length) {
      const formData = new FormData()
      formData.append("AccountType", AccountType)
      Object.keys(values).forEach(key => {
        if (!Array.isArray(values[key])) formData.append(key, values[key] || "")
        else if (values[key]?.length) {
          if (key === "AreaInCharge") formData.append(key, values[key]?.join())
          else values[key].map(img => formData.append(key, img?.originFileObj))
        } else {
          formData.append(key, [])
        }
      })

      const res = await AuthService.businessRegister(formData)
      if (res?.isError) return
      // formData.append("GuidID", res?.Object.AccountID)
      // values?.file?.map(img =>
      //   formData.append("InsertFileList", img?.originFileObj),
      // )

      // resUpload = await FileService.uploadFileList(formData)
      // }
      // if (resUpload?.isError) return

      Notice({
        isSuccess: true,
        msg: "Đăng ký thành công!",
      })
      handleCancel()
    } finally {
      setLoading(false)
    }
  }
  return (
    <CustomModal
      title="Đăng ký"
      width="1024px"
      footer={null}
      open={open}
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical">
        <SpinCustom spinning={loading}>
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <Form.Item
                label="Tên đơn vị"
                name="AccountName"
                rules={[
                  { required: true, message: "Bạn chưa nhập tên đơn vị!" },
                ]}
              >
                <Input placeholder="Nhập" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Người đại diện"
                name="Representative"
                rules={[
                  { required: true, message: "Bạn chưa nhập người đại diện!" },
                ]}
              >
                <Input placeholder="Nhập" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Chức vụ của người đại diện"
                name="Position"
                rules={[
                  {
                    required: true,
                    message: "Bạn chưa nhập chức vụ của người đại diện!",
                  },
                ]}
              >
                <Input placeholder="Nhập" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Mã số thuế"
                name="TaxCode"
                rules={[
                  {
                    required: true,
                    message: "Bạn chưa nhập mã số thuế!",
                  },
                ]}
              >
                <Input placeholder="Nhập" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Số điện thoại"
                name="PhoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Bạn chưa nhập số điện thoại!",
                  },
                  {
                    pattern: getRegexMobile(),
                    message: "Số điện thoại là chuỗi từ 8 đến 15 kí tự chữ số",
                  },
                ]}
              >
                <Input placeholder="Nhập" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item label="Fax" name="FaxNumber">
                <Input placeholder="Nhập" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Email"
                name="Email"
                rules={[
                  {
                    required: true,
                    message: "Bạn chưa nhập email!",
                  },
                  {
                    pattern: getRegexEmail(),
                    message: "Email nhập sai định dạng!",
                  },
                ]}
              >
                <Input placeholder="Nhập" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <SelectAddress floating={false} form={form} required={true} />
            </Col>
            <Col span={24}>
              <Form.Item
                label="Số nhà/tổ/thôn/xóm... "
                name="Address"
                rules={[
                  {
                    required: true,
                    message: "Bạn chưa nhập Số nhà/tổ/thôn/xóm...!",
                  },
                ]}
              >
                <Input placeholder="Số nhà/tổ/thôn/xóm... " />
              </Form.Item>
            </Col>
            {AccountType === 4 && open && !!listAddress?.length && (
              <Col span={24}>
                <Form.Item
                  name="AreaInCharge"
                  label="Khu vực phụ trách"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Thông tin không được để trống!",
                    },
                  ]}
                >
                  <TreeSelect
                    maxTagCount="responsive"
                    showCheckedStrategy={TreeSelect?.SHOW_PARENT}
                    className="region-input"
                    treeData={listAddress}
                    treeCheckable
                    treedefaultExpandAll={false}
                    loadData={onLoadData}
                    blockNode={true}
                    placeholder="Chọn Khu vực phụ trách"
                  />
                </Form.Item>
              </Col>
            )}

            <Col span={24}>
              <Form.Item
                valuePropName="fileList"
                name="LicenseFileList"
                label="Upload bản mềm giấy phép"
                getValueFromEvent={normFile}
              >
                <Upload.Dragger
                  multiple={false}
                  maxCount={1}
                  beforeUpload={() => false}
                  className="pointer"
                >
                  <Row gutter={16} className="justify-content-center">
                    <Col>
                      <SvgIcon name="cloud" />
                    </Col>
                    <Col>
                      <span>
                        Kéo thả file đính kèm hoặc{" "}
                        <span style={{ color: "#154398" }}>Chọn File</span>
                      </span>
                    </Col>
                  </Row>
                </Upload.Dragger>
              </Form.Item>
            </Col>
            <Col className="left-btn" span={24}>
              <Button
                loading={loading}
                className="register-enterprice-btn"
                type="submit"
                htmlType="submit"
                onClick={() => handlRegister()}
                btnType="primary"
              >
                {" "}
                Đăng ký
              </Button>
            </Col>
          </Row>
        </SpinCustom>
      </Form>
    </CustomModal>
  )
}

export default RegisterEnterpriseModal
