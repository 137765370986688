import styled from "styled-components"
import { Col } from "antd"
export const HomeStyled = styled.div`
  position: relative;
  .img-bgr-common {
    position: absolute;
    top: -80px;
    width: 100vw;
    height: auto;
  }
  .content-home {
    /* position: absolute;
    top: 10px; */
  }
  .time {
    font-size: 12px;
  }
  .matrix {
    position: absolute;
    width: 1920px;
    height: 1394.05px;
    left: -509px;
    top: 127.31px;

    background: #d9d9d9;
    border-radius: 200px;
    transform: matrix(0.8, -0.24, 0.93, 0.93, 0, 0);
  }

  .notice {
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    color: #ed0101;
  }
  .notice-content {
    color: #1e4193;
    font-weight: 400;
    text-transform: unset;
    margin-left: 24px;
    font-size: 13px;
  }
  .date-time {
    color: #6a7688;
    font-size: 13px;
    text-align: right;
  }
  .news {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    color: #1e4193;
  }
  .wrap-img {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      /* background-color: rgba(0, 0, 0, 0.1); */
      /* background: linear-gradient(
        89.03deg,
        #113475 30.25%,
        rgba(30, 161, 154, 0.7) 45%,
        rgba(30, 161, 154, 0) 60%
      ); */
      /* background: linear-gradient(
        89.74deg,
        #2a418a 20%,
        #278f8b 50.25%,
        rgba(31, 120, 116, 0) 70%
      ); */
    }
  }

  .home-background-cover {
    background-repeat: no-repeat !important;
    position: relative;
    background-size: cover !important;
    background-position: center !important;
    height: 700px;
  }

  .linear-background-cover {
    width: 1523px;
    background: linear-gradient(
      90.12deg,
      rgba(17, 52, 117, 0) 2.85%,
      #113475 91.44%
    );
    transform: matrix(-1, 0, 0, 1, 0, 0);
    opacity: 0.9;
    height: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    &__1 {
      background: linear-gradient(
        90.41deg,
        rgba(21, 66, 151, 0) 19.84%,
        rgba(22, 42, 91, 0.74) 53.39%,
        #57050a 96.69%
      );
      right: 0px;
    }
  }
  .title-cover {
    font-weight: 700;
    font-size: 36px;
    line-height: 150%;

    color: #fff501;
  }
  .content-cover {
    font-weight: 700;
    font-size: 48px;
    line-height: 150%;

    color: #ffffff;
    @media only screen and (min-width: 800px) {
      font-size: 64px;
    }
  }
  .center-cover {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    left: 0;
    top: 300px;
    &__1 {
      align-items: flex-end;
      width: 100%;
    }
  }
`

export const BoxStyled = styled.div`
  background: #e3f1fc;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
  .title-primary {
    font-weight: 600;
    font-size: 20px;
    color: #1e4193;
    margin-left: 74px;
  }
  .content {
    display: flex;
    position: relative;
    align-items: center;
    min-height: 50px;
  }

  .card-right {
    width: 50px;
    position: absolute;
    height: 50px;
    left: 0px;
    top: 0px;
    background: #ffffff;
    border-radius: 25px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .card-blue {
    width: 50px;
    height: 50px;
    left: 2px;
    position: absolute;
    top: 2px;
    background: #4e9ee9;
    border-radius: 25px;
  }
`

export const NewsStyled = styled.div`
  .title-large {
    font-weight: 600;
    font-size: 20px;
    color: #172b4d;
    -webkit-line-clamp: 3 !important; /* number of lines to show */
    line-clamp: 3 !important;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .content {
    font-size: 14px;
    color: #6a7688;
    -webkit-line-clamp: 3 !important; /* number of lines to show */
    line-clamp: 3 !important;
  }
  .title {
    font-weight: 600;
    font-size: 16px;
    color: #172b4d;
    -webkit-line-clamp: 2 !important; /* number of lines to show */
    line-clamp: 3 !important;
    margin-top: 24px;
    margin-bottom: 24px;
  }
`
export const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 745px;
  .slider-item {
    height: 745px;
    background-position: center;
    background-size: cover;
  }
  .swiper {
    /* clip-path: polygon(0 0, 100% 0%, 100% 84%, 0 100%); */
  }
  .swiper-pagination {
    bottom: 125px !important;
  }
  .swiper-pagination-bullet {
    background: var(--swiper-pagination-bullet-inactive-color, #fff);
  }
  .swiper-pagination-bullet-active {
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
  }
  .intro {
    background: linear-gradient(
      101.5deg,
      rgba(19, 40, 79, 0.7) 0.24%,
      rgba(255, 255, 255, 0) 106.79%
    );
    color: white;
    width: 100%;
    height: 100%;
    padding: 243px 142px;
  }
  h3 {
    font-family: "Open sans";
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 87px;
    color: inherit;
    transform: scale(0.95);
    opacity: 0.5;
    transition: transform 1.5s ease, opacity 1.5s ease;
  }
  p {
    font-style: normal;
    font-family: "Open sans";
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    transform: scale(0.95);
    opacity: 0.5;
    transition: transform 1.5s ease, opacity 1.5s ease;
  }
  .swiper-slide-active .icon {
    transform: translate(0, 0) scale(1) !important;
    opacity: 1;
  }
  .swiper-slide-active h3,
  .swiper-slide-active p {
    transform: scale(1) !important;
    opacity: 1;
    transform-origin: bottom;
  }
  .slide2 h3 {
    padding-left: 320px;
  }
  @media only screen and (max-width: 1444px) {
    .slide2 h3 {
      padding-left: 500px;
      position: relative;
      top: -120px;
    }
  }

  @media only screen and (max-width: 1000px) {
    .intro {
      padding: 136px 24px;
    }
    h3 {
      font-size: 48px;
      line-height: 64px;
    }
    .slide2 h3 {
      padding-left: 24px;
      top: 0;
    }
  }
`
export const IconWrapper = styled.div`
  position: relative;
  .icon-group {
    position: relative;
    &::before {
      content: "";
    }
  }
  .icon {
    position: absolute;
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
  }
  .icon:nth-child(1) {
    top: -125px;
    left: 320px;
    transform: translate(0, 50px) scale(0);
  }
  .icon:nth-child(2) {
    top: -193px;
    left: 174px;
    transform: translate(0, 50px) scale(0);
    transition-delay: 0.2s;
  }
  .icon:nth-child(3) {
    top: -118px;
    left: 10px;
    transform: translate(50px, 0) scale(0);
    transition-delay: 0.4s;
  }
  .icon:nth-child(4) {
    top: 70px;
    left: 44px;
    transform: translate(50px, -50px) scale(0);
    transition-delay: 0.6s;
  }
  .icon:nth-child(5) {
    top: 161px;
    left: 235px;
    transform: translate(0, -50px) scale(0);
    transition-delay: 0.8s;
  }
  .icon:nth-child(6) {
    top: 105px;
    left: 357px;
    transform: translate(-50px, -50px) scale(0);
    transition-delay: 1s;
  }
  @media only screen and (max-width: 1000px) {
    display: none;
  }
`

export const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 20px;
  bottom: 0px;
  display: flex;
  align-items: center;
  @media only screen and (min-width: 800px) {
    left: 130px;
  }
`

export const SearchStyle = styled.div`
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  padding: 50px 0px 30px;
  margin-top: 50px;
  .yellow-color {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    color: #ffd800;
    margin-bottom: 24px;
  }
`

export const PayStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #154398;
    margin: 24px;
  }
  .box-action {
    background: #154398;
    border-radius: 8px;
    width: 212px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .fs-20 {
    font-size: 20px;
  }
`

export const ServicesStyled = styled(Col)`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  /* height: 200px; */
  height: 160px;
  max-height: 272px;
  background-color: #fff;
  cursor: pointer;
  border-bottom: ${props => `${props?.borderBottom || 0}px solid #dddddd`};
  &::after {
    content: "";
    background-image: -ms-linear-gradient(0deg, #154398 0, #ee1d23 100%);
    display: block;
    height: 0;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0;
    transition: all 0.2s ease-in-out;
    background-image: linear-gradient(90deg, #154398 0%, #e4353a 100%);
  }
  :hover {
    .title-service {
      color: #ed1117;
      margin-top: 10px;
    }
    .icon-service {
      background: ${props => `url(${props?.item?.iconHover})`};
      width: 90px !important;
      height: 95px !important;
      transition: 0.3s linear;
      background-repeat: no-repeat;
      background-size: contain;
    }
    ::after {
      height: 8px;
    }
    .content-service {
      color: #ed1117;
      transform: scale(1);
    }
    .content-service2 {
      color: #ed1117;
    }
  }
  .icon-service {
    background: ${props => `url(${props?.item?.icon})`};
    width: 82px;
    height: 82px;
    opacity: 1;
    background-repeat: no-repeat;
    background-size: contain;
  }
  border-right: ${props => `${props?.borderrightwidth}px solid #dddddd`};

  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 45px auto;
  padding-top: 10px;
  padding-bottom: 10px;
  .title-service {
    color: #154398;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
  }
  .content-service {
    color: #154398;
    text-align: center;
    margin-top: 10px;
    font-size: 12px;
    padding: 0 8px;
    transform: scale(0);
    transition: all 0.3s ease-in-out;
  }
  .content-service2 {
    color: #154398;
    text-align: center;
    margin-top: 10px;
    font-size: 12px;
    padding: 0 8px;
  }
`

export const TabsNewsStyled = styled.div`
  .hover-red {
    :hover {
      color: #f0383e;
    }
  }
  .ant-tabs-content-holder {
    padding: 0px 0px;
  }
  .bread-crumb-tab-news {
    margin-top: 0px;
    margin-bottom: 15px;
    .ant-breadcrumb-link,
    .ant-breadcrumb-separator {
      color: #212529;
      font-weight: 400;
      opacity: 1;
      font-size: 14px;
    }
  }
  .see-more-2 {
    position: absolute;
    top: -50px;
    right: 0px;
    cursor: pointer;
  }

  .see-more-3 {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
  }
  .see-more {
    position: absolute;
    top: 20px;
    right: 0px;
    cursor: pointer;
  }
  .ant-tabs-nav-wrap {
    height: 36px;
  }
  .ant-tabs-tab-active {
    background: #f8f8f8;
  }
  .ant-tabs-tab {
    padding: 15px 25px;
    margin: 0px;
  }
  .ant-tabs-tab-btn {
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    text-shadow: unset !important;
    color: #154398;
    @media only screen and (min-width: 600px) {
      font-size: 22px;
    }
    @media only screen and (min-width: 550px) {
      font-size: 18px;
    }
  }
  .ant-tabs-tab-active,
  .ant-tabs-ink-bar {
    background: ${({ PrimaryColor }) => PrimaryColor};
  }
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn,
    .lib-children {
      color: ${({ TextHeaderColor }) => TextHeaderColor} !important;
    }
  }
`

export const MobileIntroduceStyled = styled.div`
  background-repeat: no-repeat !important;
  position: relative;
  background-size: cover !important;
  background-position: center !important;
  .img-animation-pointer {
    transition: transform 30s;
    position: absolute;
    top: 0;
    left: 0;
  }
  .header1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
    /* identical to box height, or 48px */
    color: #ffffff;
  }
  .header2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 150%;
    /* identical to box height, or 48px */
    color: #ffffff;
    margin-bottom: 40px;
  }
`

export const ListOptionStyled = styled.div`
  margin-top: 70px;
  .box-text {
    padding: 10px 24px;
    background: #f1f5ff;
    height: 140px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-top: 4px;
    .name {
      font-size: 10px;
      font-style: italic;
    }
  }
  .title {
    font-weight: 600;
  }
  .content {
    font-weight: 600;
    font-size: 20px;
  }

  @media only screen and (min-width: 768px) {
    .title {
      font-size: 10px;
    }
    .content {
      font-size: 14px;
    }
  }

  @media only screen and (min-width: 1200px) {
    .title {
      font-size: 12px;
    }
    .content {
      font-size: 18px;
    }
  }

  @media only screen and (min-width: 1300px) {
    .box-text {
      top: 24px;
      left: 24px;
    }
    .title {
      font-size: 14px;
    }
    .content {
      font-size: 22px;
    }
  }
  .hoverImage:hover {
    .image {
      transform: scale(1.2);
    }
  }

  .image {
    transition: all 1s;
    transform: scale(1);
    object-fit: cover;
  }

  .hoverItem:hover {
    box-shadow: 0 0 35px 0 rgb(17 51 116 / 15%);

    transition: 0.5s linear;
    ::after {
      content: "";
      display: block;
      height: 3px;
      position: absolute;
      bottom: 0px;
      left: 10px;
      right: 10px;
      transition: 0.3s;
      background: linear-gradient(90deg, #154297 0%, #ed1e24 100%);
    }
  }
`

export const NumberRepStyled = styled.div`
  .title-type-1 {
    font-size: 24px;
    color: #134197;
    font-family: "Inter Bold", sans-serif;
    padding: 20px 0;
    border-bottom: 1px solid #ebebeb;
    position: relative;
    font-weight: 700;
    margin-bottom: 30px;
    ::after {
      content: "";
      width: 80px;
      height: 4px;
      background-image: -webkit-linear-gradient(
        0deg,
        rgb(21, 67, 152) 0%,
        rgb(238, 29, 35) 100%
      );
      position: absolute;
      left: 40px;
      bottom: -2px;
      margin: 0 0 0 -40px;
    }
  }
  .box-world {
    background-repeat: no-repeat !important;
    position: relative;
    background-size: cover !important;
    background-position: center !important;
    padding-bottom: 30px;
    padding-top: 30px;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .large-text {
    font-size: 72px;
    font-weight: 700;
    background: linear-gradient(to top right, #154398 0, #ee1d23 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .small-text {
    font-size: 30px;
    background: linear-gradient(to top right, #154398 0, #ee1d23 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .fs-18 {
    font-size: 22px;
    font-weight: 700;
  }
`

export const ServiceContainerStyled = styled.div`
  .slick-track {
    width: 1080px !important;
  }
  .slick-slide {
    min-height: 100% !important;
    margin: 4px;
  }

  .slick-prev:before,
  .slick-next:before {
    display: block;
  }
  .capacity-box-image {
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
  }
`

export const SildeStyled = styled.div`
  /* height: calc(100vh - 320px);
.slick-slide {
  height: calc(100vh - 320px);
}
.slick-slide > div {
  height: calc(100vh - 320px);
  .relative {
    height: calc(100vh - 320px);
    img {
      height: calc(100vh - 320px);
      object-fit: cover;
    }
  }
} */

  height: ${props =>
    props.isSmallMobile
      ? props.isBottom
        ? "108px"
        : props.isService
        ? "45px"
        : "85px"
      : props.isMobile
      ? props.isBottom
        ? "108px"
        : props.isService
        ? "61px"
        : "102px"
      : props.isTablet
      ? props.isBottom
        ? "108px"
        : props.isService
        ? "102px"
        : "235px"
      : props.isBottom
      ? "252px"
      : props.isService
      ? "160px"
      : "350px"};
  .slick-slide {
    height: ${props =>
      props.isSmallMobile
        ? props.isBottom
          ? "108px"
          : props.isService
          ? "45px"
          : "85px"
        : props.isMobile
        ? props.isBottom
          ? "108px"
          : props.isService
          ? "61px"
          : "102px"
        : props.isTablet
        ? props.isBottom
          ? "108px"
          : props.isService
          ? "102px"
          : "235px"
        : props.isBottom
        ? "252px"
        : props.isService
        ? "160px"
        : "350px"};
  }
  .slick-slide > div {
    height: ${props =>
      props.isSmallMobile
        ? props.isBottom
          ? "108px"
          : props.isService
          ? "45px"
          : "85px"
        : props.isMobile
        ? props.isBottom
          ? "108px"
          : props.isService
          ? "61px"
          : "102px"
        : props.isTablet
        ? props.isBottom
          ? "108px"
          : props.isService
          ? "102px"
          : "235px"
        : props.isBottom
        ? "252px"
        : props.isService
        ? "160px"
        : "350px"};
    .relative {
      height: ${props =>
        props.isSmallMobile
          ? props.isBottom
            ? "108px"
            : props.isService
            ? "45px"
            : "85px"
          : props.isMobile
          ? props.isBottom
            ? "108px"
            : props.isService
            ? "61px"
            : "102px"
          : props.isTablet
          ? props.isBottom
            ? "108px"
            : props.isService
            ? "102px"
            : "235px"
          : props.isBottom
          ? "252px"
          : props.isService
          ? "160px"
          : "350px"};
      img {
        height: ${props =>
          props.isSmallMobile
            ? props.isBottom
              ? "108px"
              : props.isService
              ? "45px"
              : "85px"
            : props.isMobile
            ? props.isBottom
              ? "108px"
              : props.isService
              ? "61px"
              : "102px"
            : props.isTablet
            ? props.isBottom
              ? "108px"
              : props.isService
              ? "102px"
              : "235px"
            : props?.isBottom
            ? "252px"
            : props.isService
            ? "160px"
            : "350px"};
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .relative {
    position: relative;
  }
  position: relative;
  .slick-prev:before,
  .slick-next:before {
    display: none;
  }
  .slick-prev {
    left: 50px;
    z-index: 10;
    width: unset !important;
  }
  .slick-next {
    right: 50px;
    z-index: 10;
    width: unset !important;
  }
  .layout-slider {
    display: flex;
    flex-direction: column;
    max-width: 1500px;
    margin: 0px auto;
  }

  .option-box {
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    margin-left: 10px;
    @media only screen and (min-width: 1200px) {
      font-size: 22px;
    }
  }
  position: relative;
  .linear-background {
    width: 1523px;
    /* background: linear-gradient(
      89.98deg,
      #2a418a 18.81%,
      #1f7874 57.87%,
      rgba(31, 120, 116, 0) 95.33%
    ); */
    height: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
  }
  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #ffffff;
    transform: scale(1);
    opacity: 1;
    transition: transform 1s ease, opacity 1s ease;
    animation: Title-animate 1s linear;
    margin-left: 0;
    text-shadow: 0 1px #999;

    @media only screen and (min-width: 1200px) {
      font-size: 40px;
    }
  }
  .column {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .box-animate {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0px !important;
    margin-top: 10px;
    @media only screen and (min-width: 1200px) {
      flex-direction: row;
      align-items: center;
    }

    @media only screen and (min-width: 1200px) {
      margin-left: 0px !important;
      margin-top: 40px;
    }
  }
  @keyframes Title-animate {
    0%,
    30% {
      margin-left: 150px;
      opacity: 0;
    }
    100% {
      margin-left: 0;
      opacity: 1;
    }
  }

  .layout {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .contact {
    /* background: linear-gradient(90deg, #ed0101 23.95%, #ff5839 97.11%); */
    border: 4px solid #ffe7e6;
    border-radius: 88px;
    padding: 8px 24px 8px 50px !important;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    position: relative;
    width: fit-content;
  }
  .phone-wrapper {
    position: absolute;
    left: -15px;
    width: 60px;
    height: 60px;
    background: #fff;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -10px;
  }
  .phone-circle {
    width: 56px;
    height: 56px;
    border-radius: 28px;
    /* background: linear-gradient(90deg, #ed0101 0%, #ff5839 105.36%); */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .notice-wrapper {
    background: #ffffff;
    box-shadow: 0px 0px 72px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 0px !important;

    .header {
      background: #ed0101;
      border-radius: 8px 8px 0px 0px;
      height: 56px;
      position: relative;
      .icon-notice {
        position: absolute;
        bottom: -10px;
        right: 5px;
      }
    }
    .content {
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;

      text-align: center;
      text-transform: uppercase;
      padding: 30px;
      color: #ed0101;
    }
  }

  .info-common {
    .ant-row {
      flex-wrap: nowrap;
    }
    svg path {
      fill: #154398;
    }
    .normal {
      color: #154398;
    }
  }
  .message-wrapper {
    width: 56px;
    height: 56px;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #154398;
  }
`

export const ContentStyleAnimation = styled(Col)`
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0px !important;
  margin-top: 10px;
  text-shadow: 0 1px #999;
  animation: BoxAnimate ${props => 1 + props.order * 0.3}s linear;
  @media only screen and (min-width: 1200px) {
    flex-direction: row;
    align-items: center;
  }

  @media only screen and (min-width: 1200px) {
    margin-left: 0px !important;
    /* margin-top: 40px; */
  }
  .option-box {
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    margin-left: 10px;
    @media only screen and (min-width: 1200px) {
      font-size: 22px;
    }
  }
  @keyframes BoxAnimate {
    0%,
    60% {
      margin-left: 100px;
      opacity: 0;
    }

    100% {
      margin-left: 0px;
      opacity: 1;
    }
  }
`
