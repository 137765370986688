import React from "react"
import styled from "styled-components"
import videoBanner from "./video/video-banner.mp4"
import videoBannerMobile from "./video/video-banner-mobile.mp4"
import { Statistic } from "antd"
import moment from "moment"
import useWindowSize from "src/lib/useWindowSize"
import Notice from "src/components/Notice"
const { Countdown } = Statistic
const BannerLandingStyle = styled.div`
  position: relative;
  background: var(--color-landing-page);
  .box-time {
    background: var(--color-landing-page-box-time);
    /* background: linear-gradient(45deg, #3f51b5, #ffe500); */
    padding: 16px 24px;
    color: #d84315;
    border-radius: 8px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
    font-size: 24px !important;
  }
  .box-time2 {
    background: var(--color-landing-page-box-time);
    /* background: linear-gradient(45deg, #3f51b5, #ffe500); */
    padding: 8px 12px;
    color: #d84315;
    border-radius: 8px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    div {
      font-size: 12px !important;
    }
  }
  .video-bg {
    video {
      width: 100%;
      /* height: calc(100vh - 51px); */
      /* object-fit: cover; */
    }
    .content-bg {
      position: absolute;
      left: 10%;
      top: 40%;
      .ant-btn {
        padding: 10px 15px;
        text-align: center;
        height: 50px !important;
        background-color: ${({ theme }) => theme.primaryColor};
        border-color: ${({ theme }) => theme.primaryColor};
        color: #fff;
        :hover {
          transform: translateY(-4px);
          box-shadow: 0px 5px 30px rgba(224, 128, 129, 0.692);
        }
      }
      .content-bg-title {
        font-size: 50px;
        font-weight: 600;
        color: #faad14;
        margin-bottom: 24px;
      }
      .content-bg-label {
        width: 60%;
        font-size: 20px;
        color: #faad14;
        margin-bottom: 24px;
      }
    }
    :before {
      content: "";
      position: absolute;
      top: ${({ theme }) => `${theme.headerTopHeight};`};
      left: 0;
      right: 0;
      bottom: 0;

      background: #0000004d;
    }
  }
`
export const checkBetweenTime = () => {
  // Tạo các đối tượng Date cho các mốc thời gian
  const startDate = new Date("2024-07-01T00:00:00")
  const endDate = new Date("2024-07-10T23:00:00")

  // Lấy thời gian hiện tại
  const currentDate = new Date()
  // Kiểm tra nếu thời gian hiện tại nằm trong khoảng từ startDate đến endDate
  if (currentDate < startDate) {
    console.log("Thời gian hiện tại chưa bắt đầu")
    Notice({
      isSuccess: false,
      msg: "Sự kiện chưa bắt đầu bình chọn.",
    })
    return false
  } else if (currentDate > endDate) {
    Notice({
      isSuccess: false,
      msg: "Sự kiện đã kết thúc bình chọn",
    })
    console.log("Thời gian hiện tại đã kết thúc")
    return false
  } else {
    console.log("Thời gian hiện tại đang diễn ra")
    return true
  }
}
const BannerLanding = () => {
  const isLaptop = !!useWindowSize.isLaptop()
  const isMobile = !!useWindowSize.isMobile()

  // Tạo các đối tượng Date cho các mốc thời gian
  const startDate = new Date("2024-07-01T00:00:00")
  const endDate = new Date("2024-07-10T23:59:00")

  // Lấy thời gian hiện tại
  const currentDate = new Date()
  const boxTime =
    Date.now() +
    +moment("10/07/2024 23:59:00", "DD/MM/YYYY HH:mm:ss").diff(
      moment(),
      "seconds",
    ) *
      1000
  const checkTime = () => {
    // Kiểm tra nếu thời gian hiện tại nằm trong khoảng từ startDate đến endDate
    if (currentDate >= startDate && currentDate <= endDate) {
      console.log("Thời gian hiện tại nằm trong khoảng thời gian đã cho.")
      return true
    } else {
      console.log("Thời gian hiện tại không nằm trong khoảng thời gian đã cho.")
      return false
    }
  }
  return (
    <BannerLandingStyle>
      <div
        style={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <div className="video-bg">
          {/* <video playsinline autoplay="" muted loop>
            <source
              src={isMobile ? videoBannerMobile : videoBanner}
              type={"video/mp4"}
            />
          </video> */}
          {/* https://media.cdyt.vn/141,321d00a5e7689f4d */}
          <img
            src="https://media.cdyt.vn/142,321d00e015d6d8d7"
            // src="https://media.cdyt.vn/141,321d00a5e7689f4d"

            alt="banner"
            style={{ width: "100%", objectFit: "contain" }}
          />
        </div>
      </div>
      <div
        className="d-flex-center"
        style={{
          position: "absolute",
          bottom: !isMobile ? -75 : -58,
          left: 0,
          right: 0,
          gap: "32px",
        }}
      >
        <div className={!isMobile ? "box-time" : "box-time2"}>
          <div className="text-center mb-5 fw-600">Bắt đầu </div>
          <div className="text-center fw-600"> Từ 00h00</div>
          <div className="text-center fw-600"> Ngày 01/07/2024</div>
        </div>
        <div className={!isMobile ? "box-time" : "box-time2"}>
          <div className="text-center mb-5 fw-600">Kết thúc</div>
          <div className="text-center fw-600">Lúc 23h59</div>
          <div className="text-center fw-600">Ngày 10/07/2024</div>
        </div>
        {!isMobile && !!checkTime() && (
          <div
            className={` d-flex-center ${!isMobile ? "box-time" : "box-time2"}`}
            style={{ height: "150px", flexDirection: "column" }}
          >
            <div className="text-center fw-600">Đếm ngược</div>
            <Countdown
              valueStyle={{ color: "#d84315" }}
              className=" fw-600"
              value={boxTime}
              format="DD : HH : mm : ss "
            />
          </div>
        )}
      </div>
    </BannerLandingStyle>
  )
}

export default BannerLanding
