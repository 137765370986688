import { useState } from "react"
import RePasswordModal from "src/components/Layouts/component/Forget/components/RePasswordModal"
import VerifyForgetModal from "src/components/Layouts/component/Forget/components/VerifyForgotModal"
import ForgetModal from "src/components/Layouts/component/Forget/ForgetModal"
import FormLoginSocial from "src/components/Layouts/component/Login/FormLoginSocial"
import LoginModal from "src/components/Layouts/component/Login/LoginModal"
import RegisterEnterpriseModal from "src/components/Layouts/component/Register/components/RegisterEnterpriseModal"
import RegisterModal from "src/components/Layouts/component/Register/components/RegisterModal"
import SelectRegisterModal from "src/components/Layouts/component/Register/SelectRegisterModal"
import { StyleLoginModal } from "src/components/Layouts/styled"
import CustomModal from "src/components/Modal/CustomModal"
const LoginFBLD = ({ openLoginModal, setOpenLoginModal }) => {
  return (
    <div>
      {!!openLoginModal && (
        <CustomModal
          // title="Đăng nhập"
          width={550}
          closeIcon={<></>}
          footer={null}
          open={openLoginModal}
          onCancel={() => {
            setOpenLoginModal(false)
          }}
          style={{ top: "40%" }}
        >
          <StyleLoginModal>
            <FormLoginSocial />
          </StyleLoginModal>
        </CustomModal>
      )}
    </div>
  )
}

export default LoginFBLD
