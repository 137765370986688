import { useContext, useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import STORAGE, { getStorage } from "src/lib/storage"
import { StoreContext } from "src/lib/store"
import LandingPageLayout from "../LandingPageLayout"

function LandingRoutes() {
  const { routerStore, userStore } = useContext(StoreContext)
  const [, setRouterBeforeLogin] = routerStore
  const [user] = userStore

  const isLogin = getStorage(STORAGE.TOKEN)
  const location = useLocation()

  useEffect(() => {
    if (!isLogin)
      setRouterBeforeLogin(`${location.pathname}${location?.search}`)
  }, [isLogin])
  return (
    <LandingPageLayout>
      <Outlet />
    </LandingPageLayout>
  )
}

export default LandingRoutes
